import React from 'react'
import PropTypes from 'prop-types'

const Offerings = ({ gridItems }) => (
  <div className='columns is-multiline'>
    {gridItems.map((item, i) => {
      let borderRad = '0'
      switch (i) {
        case 0:
          borderRad = '10% 0 10% 0'
          break
        case 1:
          borderRad = '0 10% 0 10%'
          break
        case 2:
          borderRad = '0 10% 0 10%'
          break
        case 3:
          borderRad = '10% 0 10% 0'
          break
        default:
          borderRad = '10%'
          break
      }
      return (
        <div key={item.image} className='column is-6' style={{borderRadius: '5px'}}>
          <section className='section' style={{paddingBottom: '1rem', paddingTop: '1.5rem'}}>
            <p className='has-text-centered' style={{borderRadius: borderRad}}>
              <img alt='' src={item.image} style={{borderRadius: borderRad}} />
            </p>
            <p>{item.text}</p>
          </section>
        </div>
      )
    })}
  </div>
)

Offerings.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      text: PropTypes.string,
    })
  ),
}

export default Offerings
